<template>
  <div class="container">
    <BaseHeader :title="$t('views.seller.products.text_1270')">
      <BaseButton
        variant="secondary_outline"
        @click="openModal"
        v-if="
          !isMobile &&
            (!$store.getters.setPanel ||
              ($store.getters.setPanel && permissions.ProductCreate))
        "
        id="criar_produto"
        >{{ $t("views.seller.products.text_1271") }}</BaseButton
      >
    </BaseHeader>

    <div fluid class="body">
      <div class="container-pesquisa">
        <div class="inputSearch">
          <img src="@/assets/img/icons/search.svg" class="Pointer search" />
          <input
            type="text"
            :placeholder="$t('views.seller.products.text_1272')"
            v-on:input="debounce"
            v-model="search"
            class="input-busca"
            id="input-busca"
          />
        </div>
        <div v-if="!isMobile">
          <!-- ativos -->
          <BaseButton
            :variant="active ? 'active' : 'inactive'"
            class="mr-3 btn-status"
            @click="!active ? filterDisable(true) : ''"
          >
            {{ $t("views.seller.products.text_1273") }}</BaseButton
          >

          <!-- Desativados -->

          <BaseButton
            :variant="!active ? 'active' : 'inactive'"
            class="mr-3 btn-status"
            @click="active ? filterDisable(false) : ''"
          >
            {{ $t("views.seller.products.text_1274") }}</BaseButton
          >
        </div>
      </div>

      <!-- BODY -->
      <b-row class="Grid-body">
        <div class="container">
          <h5 class="my-3">
            {{ totalItems }} {{ $t("views.seller.products.text_1275") }}
          </h5>
          <transition mode="out-in">
            <div
              key="loading"
              v-if="loading"
              class="py-5 my-5 d-flex justify-content-center align-items-center"
            >
              <b-spinner variant="dark" label="Spinning"></b-spinner>
            </div>

            <b-row
              v-if="!loading && products.length > 0"
              key="lista"
              class="container"
            >
              <b-col
                cols="12"
                md="4"
                class="mb-4 mt-4 produto-item"
                v-for="(item, index) in products"
                :key="item.id"
              >
                <!-- <ProductPreview>
                  <template v-slot:button="{ openPanel }"> -->
                <div
                  class="Grid-body-thumb"
                  @click="openPanel(item.id, item.sales_page)"
                >
                  <b-img
                    v-if="item.images.length"
                    :src="item.images[0].path"
                  ></b-img>
                  <div v-else class="product-image thumb-fake">•••</div>
                </div>
                <!-- </template>
                </ProductPreview> -->
                <b-dropdown
                  v-if="
                    !isMobile &&
                      (!$store.getters.setPanel ||
                        ($store.getters.setPanel &&
                          (permissions.ProductEdit ||
                            permissions.ProductDelete ||
                            permissions.ProductCreate)))
                  "
                  size="sm"
                  right
                  dropup
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="float-right drop-personalizado"
                >
                  <template v-slot:button-content>
                    <img src="@/assets/img/icons/more.svg" class="btn-more" />
                  </template>
                  <!-- <b-dropdown-item @click="copyLink(item)" class="drop-item">
                    Link de venda
                  </b-dropdown-item> -->

                  <b-dropdown-item
                    @click="editProduct(item.id)"
                    class="drop-item"
                    v-if="
                      !isMobile &&
                        (!$store.getters.setPanel ||
                          ($store.getters.setPanel && permissions.ProductEdit))
                    "
                  >
                    {{ $t("views.seller.products.text_1459") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="duplicarProduto(item.id, item.name)"
                    class="drop-item"
                    v-if="
                      !$store.getters.setPanel ||
                        ($store.getters.setPanel && permissions.ProductCreate)
                    "
                  >
                    {{ $t("views.seller.products.text_1460") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="
                      item.is_active &&
                        (!$store.getters.setPanel ||
                          ($store.getters.setPanel &&
                            permissions.ProductDelete))
                    "
                    @click="statusProduto(index, item.id, false)"
                    class="drop-item"
                  >
                    {{ $t("views.seller.products.text_1461") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="!item.is_active"
                    @click="statusProduto(index, item.id, true)"
                    class="drop-item"
                  >
                    {{ $t("views.seller.products.text_1462") }}
                  </b-dropdown-item>
                </b-dropdown>
                <!-- <ProductPreview>
                  <template v-slot:button="{ openPanel }"> -->
                <div
                  class="Grid-body-title"
                  @click="openPanel(item.id, item.sales_page)"
                >
                  {{ item.name }}
                </div>
                <!-- </template>
                </ProductPreview> -->
                <div class="top-info">
                  <div class="icons-info">
                    <div class="infos-top">
                      <div class="item-info" :id="`sales-count-${item.id}`">
                        <img src="@/assets/img/icons/my-sales.svg" />
                        {{ item.sales }}
                      </div>
                      <b-tooltip
                        :target="`sales-count-${item.id}`"
                        :title="$t('views.seller.products.text_1463')"
                      />
                      <div
                        class="item-info pointer"
                        :id="`affiliates-count-${item.id}`"
                        @click="openAffiliates(item.id, item.name)"
                        v-if="item.affiliation"
                      >
                        <img
                          src="@/assets/img/icons/affiliate.svg"
                          class="small"
                        />
                        {{ item.affiliates }}
                      </div>
                      <b-tooltip
                        :target="`affiliates-count-${item.id}`"
                        :title="$t('views.seller.products.text_1464')"
                      />
                    </div>
                    <div
                      class="avaliacao"
                      :id="'avaliacao' + item.id"
                      v-if="item.rating_count"
                    >
                      <img src="@/assets/img/icons/avaliacao.svg" />
                      {{ Math.floor(item.rating_avg) }}.0
                      <span>({{ item.rating_count }})</span>
                    </div>
                    <div class="avaliacao" v-else></div>
                    <b-tooltip
                      v-if="item.rating_count"
                      :target="'avaliacao' + item.id"
                      :title="$t('views.seller.products.text_1465')"
                    />
                  </div>

                  <div class="acoes-rapidas">
                    <b-tooltip
                      :target="'btn-checkout-' + item.id"
                      :title="$t('views.seller.products.text_1276')"
                      placement="top"
                    />
                    <img
                      src="@/assets/img/icons/checkout-link.svg"
                      :id="'btn-checkout-' + item.id"
                      :title="$t('views.seller.products.text_1277')"
                      @click="copyLinkVendas(item, 'Checkout')"
                    />

                    <b-tooltip
                      :target="'btn-vendas-' + item.id"
                      :title="$t('views.seller.products.text_1278')"
                      placement="top"
                    />
                    <img
                      v-if="item.links.length"
                      src="@/assets/img/icons/sales.svg"
                      :id="'btn-vendas-' + item.id"
                      class="ml-3"
                      :title="$t('views.seller.products.text_1279')"
                      @click="copyLinkVendas(item, 'Vendas')"
                    />
                  </div>
                </div>
                <div class="Grid-body-money">
                  {{
                    filterCurrency(
                      item.offers.length ? item.offers[0].currency_id : 1
                    )
                  }}
                  {{
                    formatMoney(
                      item.offers.length ? item.offers[0].amount : item.amount
                    )
                  }}
                </div>

                <!-- <div class="Grid-body-description" v-if="item.description">
                  {{
                    formatMoney(
                      item.offers ? item.offers[0].amount : item.amount
                    )
                  }}
                </div>
                <div class="Grid-body-description"></div> -->
              </b-col>
            </b-row>

            <b-row
              key="nao-encontrado"
              v-if="!loading && products.length == 0"
              class="justify-content-center"
            >
              <p class="nao-encontrado">
                {{ $t("views.seller.products.text_1280") }}
              </p>
            </b-row>
          </transition>
        </div>
      </b-row>
      <!-- END BODY -->
      <Paginate
        v-if="products.length > 0"
        :totalPages="pagination.totalPages"
        :activePage="pagination.currentPage"
        @to-page="toPage"
        @per-page="perPage"
      />
    </div>

    <!-- modals -->
    <ModalNew @close="fetchProducts" />
    <ProductPreview ref="productPreview" />
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
import ProductService from "@/services/resources/ProductService";
import DashboardService from "@/services/resources/DashboardService";
import DuplicateService from "@/services/resources/DuplicateService";
import Paginate from "@/components/shared/Paginate";
import ModalNew from "@/components/Seller/Products/modals/new";
import ProductPreview from "@/components/shared/ProductPreview";
import Vue from "vue";
import Money from "@/mixins/money";
import _ from "lodash";
import { symbolCurrency } from "@/helpers.js";
import Cookies from "js-cookie";
import PermissionRedirect from "@/services/PermissionRedirect";

const service = ProductService.build();
const serviceDashboard = DashboardService.build();
const serviceDuplicate = DuplicateService.build();

export default {
  name: "Products",
  components: {
    Paginate,
    ModalNew,
    ProductPreview,
  },
  mixins: [Money],
  data() {
    return {
      reviewRating: 4.6,
      search: "",
      products: [],
      loading: true,
      total: 0,
      slug: "",
      totalItems: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      active: true,
      client: {
        width: 0,
      },
      permissions: {},
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    salesPage() {
      return process.env.VUE_SALES_PAGE;
    },
    baseURL() {
      return process.env.VUE_SALES_PAGE;
    },
  },
  methods: {
    filterCurrency(id) {
      if (id) {
        return symbolCurrency(id);
      }
      return "R$";
    },
    openAffiliates(id, name) {
      if (!this.$store.getters.setPanel)
        this.$router.push(`/afiliados/?p_id=${id}&p_name=${name}`);
    },

    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },

    duplicarProduto(id_product, name) {
      Vue.swal({
        title: this.$t("views.seller.products.text_1460"),
        text: `${this.$t("views.seller.products.text_1281")} ${name}?`,
        type: "success",
        showCancelButton: true,
        confirmButtonText: this.$t("views.seller.products.text_1460"),
        cancelButtonText: this.$t("views.seller.products.text_1466"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-secondary mt-3 mb-3",
        cancelButtonClass: "button button-info2 mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          serviceDuplicate
            .createId({ id: id_product })
            .then(() => {
              this.fetchProducts();
              this.$bvToast.toast(this.$t("views.seller.products.text_1282"), {
                title: this.$t("views.seller.products.text_1467"),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
              this.submit = false;
            });
        }
      });
    },

    debounce: _.debounce(function() {
      this.fetchProducts(this.search);
    }, 500),
    openPanel(id, sales_page) {
      this.$router.push({ query: { p_id: id } }).catch(() => {});

      setTimeout(() => {
        this.$refs.productPreview.openPanel(id, sales_page);
        this.loading = false;
      });
    },
    editProduct(id_product) {
      if (
        !this.$store.getters.setPanel ||
        (this.$store.getters.setPanel && this.permissions.ProductEdit)
      ) {
        this.loading = true;
        this.$router.push(`/produtos/edicao-produto/${id_product}`);
      }
    },
    openModal() {
      this.$bvModal.show("product-new");
    },

    filterDisable(disabled) {
      this.active = disabled;
      this.pagination.currentPage = 1;
      this.fetchProducts();
    },

    statusProduto(index_lista, id, status) {
      const _product = { id: id, is_active: status };
      if (status) {
        this.ativarProduto(_product);
      } else {
        this.desativarProduto(index_lista, _product);
      }
    },
    ativarProduto(produto) {
      Vue.swal({
        title: this.$t("views.seller.products.text_1462"),
        text: this.$t("views.seller.products.text_1284"),
        type: "success",
        showCancelButton: true,
        confirmButtonText: this.$t("views.seller.products.text_1462"),
        cancelButtonText: this.$t("views.seller.products.text_1466"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-success mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const _product = produto;
          service
            .update(_product)
            .then(() => {
              this.fetchProducts();
              this.$bvToast.toast(this.$t("views.seller.products.text_1285"), {
                title: this.$t("views.seller.products.text_1467"),
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    desativarProduto(index_lista, produto) {
      Vue.swal({
        title: this.$t("views.seller.products.text_1286"),
        text: this.$t("views.seller.products.text_1287"),
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("views.seller.products.text_1286"),
        cancelButtonText: this.$t("views.seller.products.text_1466"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-link-info mt-3 mb-3",
        cancelButtonClass: "button button-info2 mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const _product = produto;
          service
            .update(_product)
            .then(() => {
              // this.fetchProducts();
              this.$bvToast.toast(this.$t("views.seller.products.text_1288"), {
                title: this.$t("views.seller.products.text_1467"),
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.products.splice(index_lista, 1);
              this.totalItems--;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchProducts();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchProducts();
    },
    fetchReports() {
      if (this.permissions.DashboardRead) {
        let data = {
          types: ["products"],
        };

        serviceDashboard
          .search(data)
          .then((response) => {
            this.total = response.product;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    fetchProducts() {
      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      this.pagination.totalPages = 1;

      if (this.search.length) {
        data.search = this.search;
        data.page = 1;
      }
      if (!this.active) {
        data.active = false;
      }
      this.loading = true;

      service
        .search(data)
        .then((response) => {
          this.products = response.data;
          this.pagination.totalPages = response.last_page;
          this.totalItems = response.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    copyLinkVendas(item, tipo) {
      // Copy link
      const el = document.createElement("textarea");
      if (tipo == "Vendas") {
        el.value = item.links.length ? item.links[0].url : "";
      } else {
        el.value = this.baseURL + "/" + item.id;
      }
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(
        ` ${this.$t("views.seller.products.text_1469")} ${tipo} ${this.$t(
          "views.seller.products.text_1289"
        )}`,
        {
          title: this.$t("views.seller.products.text_1468"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
    },
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          this.permissions = dados.reduce((obj, item) => {
            obj[item.name] = true;
            return obj;
          }, {});
          PermissionRedirect("ProductRead");
        }
      }
    },
  },
  mounted() {
    this.fetchProducts();
    this.fetchReports();
    this.getPermissions();
    window.addEventListener("keyup", (e) => {
      if (e.key == "/" || e.keyCode == 193) {
        if (document.querySelector("#input-busca"))
          document.querySelector("#input-busca").focus();
      }
    });

    // abrindo produto unico
    if (typeof this.$route.query.p_id != "undefined") {
      setTimeout(() => {
        this.$refs.productPreview.openPanel(this.$route.query.p_id);
        this.loading = false;
      }, 1000);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script>

<style scoped>
.container-pesquisa {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.total-produtos {
  font-size: 14px;
  color: #81858e;
}
.total-produtos span {
  font-weight: 600;
  font-size: 15px;
}
.inputSearch {
  position: relative;
}
.input-busca {
  width: 400px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  width: 560px;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1) !important; */
  /* border-color: #ededf0 !important; */
  border-color: #002363 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 20px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 1;
  transition: 0.3s;
}
.btn-more {
  background: rgba(0, 35, 99, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px 15px;
  border-radius: 5px;
}
.produto-item {
  transition: 0.3s;
}
/* .produto-item:hover {
  transform: translate3d(0, -5px, 0);
} */
.drop-personalizado {
  right: -8px;
}
.acoes-rapidas {
  display: flex;
  align-items: center;
  /* margin-top: -15px; */
}
.acoes-rapidas img {
  cursor: pointer;
}
.acoes-rapidas img:first-child {
  max-width: 18px;
}
.acoes-rapidas img:nth-child(2) {
  max-width: 15px;
  margin-left: 13px;
}
.thumb-fake {
  width: 100%;
  color: silver;
  font-size: 32px;
  letter-spacing: 2px;
  justify-content: center;
  text-align: center;
}
.btn-status {
  height: 45px;
  padding: 0 20px !important;
  font-size: 13px;
}
@media screen and (max-width: 768px) {
  #criar_produto {
    display: none !important;
  }
  header {
    display: block;
  }
  nav {
    display: flex;
    justify-content: flex-end;
  }
  nav button {
    margin-top: 20px;
  }
  .input-busca:hover,
  .input-busca:focus,
  .input-busca {
    width: 100%;
  }
  .container-pesquisa {
    display: block;
  }
  h5 {
    font-size: 16px;
    margin-left: 20px;
  }
  .produto-item + .produto-item {
    margin-top: 10px;
  }
}
.top-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}
.avaliacao {
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 600;
  font-size: 12px;
  max-width: 60px;
}
.avaliacao img {
  width: 12px;
}
.avaliacao span {
  font-weight: normal;
  color: #81858e;
}
.icons-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.infos-top,
.item-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.item-info {
  gap: 10px;
  font-weight: 600;
  font-size: 12px;
}
.item-info img {
  max-width: 18px;
  filter: invert(50%);
}
.item-info img.small {
  max-width: 14px;
}
.pointer {
  cursor: pointer;
}
</style>
